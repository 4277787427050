import React from 'react';
import {Paragraph, SmallHeader} from 'components/Typography';
import {
  Project,
  Team,
  Flexibility,
  Complementarity,
} from 'components/Icons/AboutUs';

export const useEnIconCardsData = () => {
  return [
    {
      icon:
        <Project width="110px" height="110px" color="#ABC539"/>,
      title:
        <SmallHeader>Tailor-made projects</SmallHeader>,
      text: (
        <Paragraph>
          Our materials are prepared in an individual way and from scratch, we use proven solutions and patents developed in-house.
        </Paragraph>
      ),
    },
    {
      icon:
        <Team width="110px" height="110px" color="#ABC539"/>,
      title: (
        <SmallHeader>
          An experienced staff
        </SmallHeader>
      ),
      text: (
        <Paragraph>
          The employees of the departments care about each detail, no matter whether it is the preliminary design or the final product.
        </Paragraph>
      ),
    },
    {
      icon:
        <Flexibility width="110px" height="110px" color="#ABC539"/>,
      title:
        <SmallHeader>Flexibility in acting</SmallHeader>,
      text: (
        <Paragraph>
          We adjust ourselves to the pricing and quality requirements of the client - we can work with both a&nbsp;tight budget and with a need for completely innovative creativity.
        </Paragraph>
      ),
    },
    {
      icon:
        <Complementarity width="110px" height="110px" color="#ABC539"/>,
      title:
        <SmallHeader>Complementarity of services</SmallHeader>,
      text: (
        <Paragraph>
          From the design to implementation at the POS - we offer a&nbsp;full package of services, allowing for delivery of fully ready, turnkey products, including related logistics and servicing.
        </Paragraph>
      ),
    },
  ];
};
