import React from 'react';

import {useData} from '../../data/en/who-are-we';
import WhoAreWe from '../../sections/AboutCompany';

const Index = () => {
  const data = useData();

  return (
    <WhoAreWe data={data}/>
  );
};
export default Index;
