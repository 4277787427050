import React from 'react';
import {useServiceSliderData} from '../../shared/Hooks/AboutCompany';
import {useEnIconCardsData} from '../../shared/Hooks/AboutCompany/useEnIconCardsData';
import {useEnTestimonialSliderData} from '../../shared/Hooks/AboutCompany/useEnTestimonialSliderData';
import Img from 'gatsby-image';
import {useEnBackgroundImage} from '../../shared/Hooks/Background/useEnBackgroundImage';

export const useData = () => {
  const slides = useServiceSliderData();
  const cards = useEnIconCardsData();
  const testimonials = useEnTestimonialSliderData();
  const backgroundImgs = useEnBackgroundImage();

  return {
    title: 'Who are we',
    header: {
      parallaxText: (
        <Img fixed={backgroundImgs.aboutUs.childImageSharp.fixed}/>
      ),
      subtitle: 'Who are we',
      title: 'About the company',
      text: <>
        We are an experienced team providing services in the field of design and manufacture of POS materials. For over 15 years, we have been delivering complex solutions responding to the needs of our clients from numerous industries and areas of activity.
        <br/>
        <br/>
        We treat every project individually and as a new challenge. We cooperate with the client, sharing knowledge and experience as well as following new trends in the market. We treat the entrusted topics in a comprehensive way, starting from the visualisation stage, through manufacture, up to logistics and implementation at the POS.
      </>,
    },
    services: {
      hash: 'support-at-every-stage',
      title: 'Support at every stage',
      text: <>
        We achieve the objectives determined by our clients by means of our teams of competent employees utilizing their extensive knowledge and experience in the POS materials industry - from the design department to the production department. We visualise and construct, we prototype and manufacture. We store, deliver, install and set up. Everything from one facility.
      </>,
      slides,
    },
    iconCards: {
      title: 'Why us?',
      cards,
    },
    testimonials: {
      title: 'References',
      testimonials,
    },
  };
};
