export const useEnTestimonialSliderData = () => {
  const data = [
    {
      clientName: "Dariusz Hołowaty",
      companyName:
        "Dyrektor ds. Sprzedaży detalicznej i Rozwoju sieci firmy Składy VOX sp. z o.o. sp. k.",
      testimony:
        "Z przyjemnością rekomenduję firmę ART-PROGRES z którą współpracuję od lat powierzając kompleksową obsługę realizacji materiałów ekspozycyjnych POS. Firma posiada szerokie kompetencje w zakresie: przygotowania koncepcji, projektu, prototypu, dystrybucji i serwisu ekspozytorów jak i innych elementów POS. Kreatywność i pomysłowość zespołu ART-PROGRES, oceniam bardzo dobrze. Ponadto jest to kontrahent solidny, odpowiedzialny i godny zaufania.",
    },
    {
      clientName: "Tomasz Zyśko",
      companyName: "Trade Marketing Director w firmie Cersanit S.A.",
      testimony:
        "Wieloletnia współpraca z firmą ART-PROGRES pokazuje, że osiągnięcie zamierzonego celu nigdy nie jest dziełem przypadku. To efekt skrupulatnego planowania i detalicznej egzekucji, od etapu kreacji, poprzez proces produkcji, na operacjonalizacji rynkowej kończąc. Za skrupulatnością w działaniu stoi ponadto pasja i zaangażowanie w pracy. Posiadanie takiego partnera biznesowego daje spokój i pewność w działaniu.",
    },
    {
      clientName: "Anna Loska",
      companyName: "Koordynator Marketingu Handlowego firmy Prymat sp. z o.o.",
      testimony:
        "Z firmą ART-PROGRES współpracujemy od 3 lat. Okres ten zaowocował realizacją wielu ciekawych projektów, szczególnie projekty z zakresu POS sklejkowych są obecne w sieciach handlowych po dziś dzień. Duża elastyczność oraz terminy realizacji to jedna z wielu zalet dostawcy. Nie można pominąć także działu projektowego, który bardzo szybko odpowiadania na nasze potrzeby przy okazji proponując alternatywne projekty dla jednego zadania. Z czystym sumieniem polecam ART-PROGRES jako sprawdzonego dostawcę materiałów POS.",
    },
    {
      clientName: "Grzegorz Duszyński",
      companyName:
        "Kierownik ds. Marketingu Handlowego w firmie ANIMEX Foods Sp. z o.o.",
      testimony:
        "Koncept UniCooler rozwiązał nam największy problem jakim jest szeroko rozumiana logistyka urządzeń. Teraz każdy nasz przedstawiciel handlowy jest w stanie sam dostarczyć urządzenie do wybranej lokalizacji, jak i przewieźć je między placówkami. Odszedł nam kosztowny proces specjalnego transportu.",
    },
    {
      clientName: "Mariusz Stasieńko",
      companyName: "Kierownik ds. Marketingu w firmie Sokołów S.A.",
      testimony:
        "Sokołów S.A. współpracuje z firmą ART-PROGRES od dłuższego czasu realizując różne projekty, między innymi lodówki UniCooler. Jako jedni z pierwszych na rynku Polskim, mieliśmy możliwość przetestowania tego projektu w różnych konfiguracjach. Lodówki spełniły nasze oczekiwania i korzystamy z tego typu rozwiązań przy działaniach promocyjnych do prezentacji naszych produktów.",
    },
  ]

  return data
}
